$declarations: (
  m: margin,
  p: padding,
);
$positions: (
  t: (top),
  l: (left),
  r: (right),
  b: (bottom),
  x: (left, right),
  y: (top, bottom),
);
$sizes: (0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);

@function get($key, $map) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }
  @warn "Unknown `#{$key}` in #{$map}.";
  @return null;
}

@each $declaration in map-keys($declarations) {
  @each $pos in map-keys($positions) {
    @each $size in $sizes {

      .#{$declaration}-#{$pos}-#{$size} {
        @each $position in get($pos, $positions) {
          #{get($declaration, $declarations)}-#{$position}: #{$size}px;
        }
      }
      .#{$declaration}-#{$pos}-#{$size}-f {
        @each $position in get($pos, $positions) {
          #{get($declaration, $declarations)}-#{$position}: #{$size}px !important;
        }
      }

      @if $declaration == p {

        .padding-#{$size} {
          padding: #{$size}px;
        }
        .padding-#{$size}-f {
          padding: #{$size}px !important;
        }
      }

      @if $declaration == m {

        .margin-#{$size} {
          margin: #{$size}px;
        }
        .margin-#{$size}-f {
          margin: #{$size}px !important;
        }
        .margin-#{$size}-n {
          margin: -#{$size}px;
        }
        .margin-#{$size}-n-f {
          margin: -#{$size}px !important;
        }

        .#{$declaration}-#{$pos}-#{$size}-n {
          @each $position in get($pos, $positions) {
            #{get($declaration, $declarations)}-#{$position}: -#{$size}px;
          }
        }
        .#{$declaration}-#{$pos}-#{$size}-f-n {
          @each $position in get($pos, $positions) {
            #{get($declaration, $declarations)}-#{$position}: -#{$size}px !important;
          }
        }

      }
    }
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold-light, .font-weight-light {
  font-weight: 300;
}

.text-bold-normal, .font-weight-normal {
  font-weight: 400;
}

.text-bold, .font-weight-bold {
  font-weight: 700;
}

.text-italic, .font-italic {
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;;
}

.text-black-50 {
  color: rgba(0, 0, 0, .5);
}

.text-white-50 {
  color: rgba(255, 255, 255, .5);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-no-shadow {
  text-shadow: none !important;
}

.no-shadow {
  box-shadow: none !important;
}


.cursor-pointer {
  cursor: pointer;
}


.h100, .height100, .full-height {
  height: 100% !important;
}

.w100, .width100, .full-width {
  width: 100%;
}

.hide, .hidden {
  display: none;
}

.hidden-f {
  display: none !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}


.inline {
  display: inline-block;
}

.block {
  display: block;
}

.b-blank {
  border-color: #000;
}


.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-overflow {
  overflow: hidden;
}

.auto-overflow {
  overflow: auto;
}

.inherit-size {
  width: inherit;
  height: inherit;
}

.inherit-height {
  height: inherit;
}

.scroll-x-auto {
  overflow-x: auto;
}

.scroll-x-hidden {
  overflow-x: hidden;
}

.auto-height {
  height: auto;
}

.auto-width {
  height: auto;
}

.auto-margin {
  margin: auto;
}

.m-x-a, .center-margin, .auto-margin-x {
  margin-left: auto;
  margin-right: auto;
}

.m-y-a, .auto-margin-y {
  margin-top: auto;
  margin-bottom: auto;
}

.m-l-a, .auto-margin-left {
  margin-left: auto;
}

.m-r-a, .auto-margin-right {
  margin-right: auto;
}


.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0;
  //border-color: transparent;
}

.b-r {
  border-right-width: 1px;
}

.b-l {
  border-left-width: 1px;
}

.b-t {
  border-top-width: 1px;
}

.b-b {
  border-bottom-width: 1px;
}

.b-a {
  border-width: 1px;
}

.b-dashed {
  border-style: dashed;
}

.b-thick {
  border-width: 2px;
}

.b-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.b-transparent-white {
  border-color: rgba(255, 255, 255, 0);
}

.b-grey {
  border-color: #e6e6e6;
}

.c-white {
  color: #fff;
}

.b-white {
  border-color: #fff;
}

.bg-white {
  background-color: #fff;
}


.b-rad-round {
  border-radius: 50%;
}

.b-rad-xs {
  border-radius: 2px;
}

.b-rad-sm {
  border-radius: 3px;
}

.b-rad-md {
  border-radius: 5px;
}

.b-rad-lg {
  border-radius: 7px;
}

.b-rad-none {
  border-radius: 0;
}

.no-border {
  border: none !important;
}

.bg-none, .no-bg {
  background: none;
}


.flex, .flex-display, .display-flex {
  display: flex;
}

.flex-display {
  flex-wrap: wrap;
}

.flex-inline {
  display: inline-flex;
}


.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-direction-row, .flex-rows {
  display: flex;
  flex-direction: row;
}

.flex-direction-row-rev, .flex-rows-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.flex-direction-column-rev, .flex-columns-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-v-center {
  display: flex;
  align-items: center;
}

.flex-h-center {
  display: flex;
  justify-content: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-grow {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-equal-height {
  display: flex;
}

.flex-equal-height > div {
  align-self: stretch;
}


.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}


.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}


.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}
.justify-start-force {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

@mixin loader($color: #b31e23, $bg: #fbfbfb, $size: 30px, $border-size: 6px, $time: 2s) {
  border: $border-size solid $bg;
  border-top: $border-size solid $color;
  border-radius: 50%;
  width: $size;
  height: $size;
  animation: cloakspin $time linear infinite;
}

@keyframes cloakspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.addiesaas-component {

  // hide message if component is loaded
  &::before,
  &::after,
  & > .addiesaas-preloader-message,
  &.with-preloader-message > .addiesaas-preloader-message {
    display: none;
  }
  // show preloader animation and /or preloader message before the vuejs components are rendered
  // to render preloader animation in the root element set `class="with-preloader"` in to Wordpress shortcode
  // to render preloader message set `preloader-message="Loading..."` in to Wordpress shortcode
  // to render preloader animation as :before element of the preloader message do not set class="with-preloader"
  //    - this is not advised as there are style limitation in responsive (smaller) views. You have add additional
  //      styles for smaller responsive break-points to achieve better wrapping and positioning of the elements
  &[v-cloak],
  &.permanent-loader {
      // show root if it has preloader elements
    & > .addiesaas-preloader-message,
    &.with-preloader-message > .addiesaas-preloader-message,
    &.with-custom-preloader,
    &.with-preloader-message,
    &.with-preloader {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &::after,
      &::before {
        display: flex;
        content: '';
      }
    }

    &.with-custom-preloader,
    &.with-preloader-message,
    &.with-preloader {
      padding: 50px;
    }

    &.with-preloader-message,
    &.with-preloader {
      flex-wrap: wrap;

      &::before {
        @include loader;
        content: '';
        flex-shrink: 0;
        z-index: 1;
        margin: 10px;
      }
    }

    &.no-preloader {
      &::before,
      &::after,
      & > .addiesaas-preloader-message,
      &.with-preloader-message > .addiesaas-preloader-message {
        display: none;
      }
    }
  }


}



